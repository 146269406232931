import React from "react";
import { Button } from "react-bootstrap";

import { button } from "@cssmodules/greenButton.module.scss";
export const GreenButton = props => {
    return (
        <Button
            {...props}
            className={
                props.className ? `${button} ${props.className}` : button
            }
        >
            {props.children}
        </Button>
    );
};
